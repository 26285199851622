import $ from "jquery";
import Swiper from "swiper/bundle";

window.$ = $;

window.popupStorySwiper = () => {
    const swiper = new Swiper("#popup-story .swiper", {
        slidesPerView: 1,
        pagination: {
            el: "#popup-story .swiper-pagination",
            clickable: true,
        },
    });
};

window.popupAcneSwiper = () => {
    const swiper = new Swiper("#popup-acne .swiper", {
        slidesPerView: 1,
        pagination: {
          el: "#popup-acne .swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: "#popup-acne .swiper-button-next",
          prevEl: "#popup-acne .swiper-button-prev",
        },
    });
};